import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isMobile from 'ismobilejs';

import s from './index.scss';

class Catalog extends Component {
  renderAppCard(el) {
    return (
      <article className={s['item_type_' + el.type]}>
        <div
          className={s['item_icon'] + ' ' + s['item_icon_' + el.id]}
          data-soft={
            this.props.stat.appPlatformType + '::catalog::' + el.id + '::icon'
          }
        />
        <h2
          className={s.item_title}
          data-soft={
            this.props.stat.appPlatformType + '::catalog::' + el.id + '::title'
          }
          dangerouslySetInnerHTML={{ __html: el.title }}
        />
        <p
          className={s.item_description}
          data-soft={
            this.props.stat.appPlatformType + '::catalog::' + el.id + '::text'
          }
          dangerouslySetInnerHTML={{ __html: el.description }}
        />
        <div
          className={s.button}
          data-soft={
            this.props.stat.appPlatformType + '::catalog::' + el.id + '::button'
          }
        >
          Подробнее
        </div>
      </article>
    );
  }

  renderAppsList() {
    const list = this.props.location
      ? this.props.apps
      : this.props.apps.filter(el => !(
        el.type === 'desktop' &&
        (typeof window !== 'undefined' ? isMobile(window.navigator).any : false)
      ));
    return list.map((el, i) => {
      return (
        <a href={'/' + el.id} key={i} className={s.item}>
          {this.renderAppCard(el)}
        </a>
      );
    });
  }

  render() {
    return (
      <section className={s.root}>
        <h1 className={s.category_title}>{this.props.title}</h1>
        {this.renderAppsList()}
      </section>
    );
  }
}

Catalog.propTypes = {
  title: PropTypes.string.isRequired,
  apps: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    })
  ).isRequired,
  stat: PropTypes.shape({
    appPlatformType: PropTypes.string.isRequired,
  }).isRequired,
};

export const CatalogPure = Catalog;

export default Catalog;
