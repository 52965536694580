import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import bowser from 'bowser';

import {
  goal,
  YABRO_CLICK_INSTALL_1,
  YABRO_CLICK_INSTALL_2,
  YABRO_CLICK_INSTALL_ALL,
  YABRO_SLIDE_CLICK_INSTALL_1,
} from '../../../common/analytics';

import s from './style.scss';

const Modal = ({ children }) => {
  return ReactDOM.createPortal(children, document.getElementById('modal'));
};

class YaBroInstallButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isClient: false,
      isHintVisible: false,
    };
    this.listenKeyboard = this.listenKeyboard.bind(this);
    this.handleInstallClick = this.handleInstallClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.bodyPreviousOverflow = undefined;
  }

  componentDidMount() {
    this.setState(
      {
        isClient: true,
      },
      () => {
        if (window && window.navigator) {
          const browser = bowser.getParser(window.navigator.userAgent).parse()
            .parsedResult;
          this.browserNameFull = browser.browser.name;
          this.browserName = [
            'Chrome',
            'Opera',
            'Internet Explorer',
            'Microsoft Edge',
          ].includes(this.browserNameFull)
            ? this.browserNameFull
                .replace('Internet Explorer', 'ie')
                .replace('Microsoft Edge', 'ie')
                .toLowerCase()
            : undefined;
          this.browserOS = browser.os.name.toLowerCase();
        }
      }
    );
    window.addEventListener('keydown', this.listenKeyboard, true);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.listenKeyboard, true);
  }

  listenKeyboard(e) {
    const ESC_KEY_CODE = 27;
    if (e.keyCode === ESC_KEY_CODE) {
      this.handleClose(e);
    }
  }

  handleInstallClick(e) {
    let goalType;
    switch (this.props.version) {
      case 1: {
        goalType = YABRO_CLICK_INSTALL_1;
        break;
      }
      case 2: {
        goalType = YABRO_CLICK_INSTALL_2;
        break;
      }
      case 3:
      case 4: {
        goalType = YABRO_SLIDE_CLICK_INSTALL_1;
        break;
      }
    }
    if (goalType) {
      goal(goalType);
    }
    if (this.props.version === 1 || this.props.version === 2) {
      goal(YABRO_CLICK_INSTALL_ALL);
    }
    if (this.browserName) {
      const body = document.querySelector('body');
      this.bodyPreviousOverflow = window.getComputedStyle(body).overflow;
      body.style.overflow = 'hidden';
      this.setState({
        isHintVisible: true,
      });
    }
  }

  handleClose(e) {
    e.preventDefault();
    e.stopPropagation();
    const body = document.querySelector('body');
    body.style.overflow = this.bodyPreviousOverflow;
    this.setState({
      isHintVisible: false,
    });
  }

  render() {
    let link = 'https://soft.rambler.ru/yabrowser/download?';
    switch (this.props.version) {
      case 1:
      case 2: {
        link += 'vid=12&hash=7dc464331fab306b4e49dbfe068fae60';
        break;
      }
      case 3: {
        link += 'vid=1&hash=f99c781edec23ab967ad71299c9d8902';
        break;
      }
      case 4: {
        link += 'vid=3&hash=d221039167341b64e21fbe737c1aaa41';
        break;
      }
    }
    if (this.state.isClient && this.props.queryParams) {
      link +=
        ([1, 2, 3, 4].includes(this.props.version) ? '&' : '') +
        this.props.queryParams;
    }
    let phrase = 'Чтобы установить его, нажмите ';
    switch (this.browserNameFull) {
      case 'Opera':
        phrase += '<nobr>в правом</nobr> верхнем углу';
        break;
      case 'Internet Explorer':
      case 'Microsoft Edge':
        phrase += 'внизу';
        break;
      default:
        phrase += '<nobr>в левом</nobr> нижнем углу';
    }
    phrase +=
      ' <nobr>на загружаемый</nobr> файл, дождитесь запуска и следуйте инструкциям';
    return (
      <div>
        <a
          href={link}
          className={s.button}
          onClick={this.handleInstallClick}
          data-ui="yabro::install"
          data-soft={
            this.props.stat.appPlatformType + '::slider::button::yandex_browser'
          }
        >
          Установить {this.props.version !== 3 ? 'Яндекс.Браузер' : ''}
          {this.state.isHintVisible && (
            <Modal>
              <span
                className={
                  s.hint +
                  ' ' +
                  s['hint_' + this.browserName] +
                  ' ' +
                  s['hint_' + this.browserOS]
                }
                onClick={this.handleClose}
              >
                <span className={s.hint_close} />
                <span className={s.hint_content}>
                  <span className={s.hint_title}>
                    Спасибо, что скачали Яндекс.Браузер{' '}
                    <nobr>с элементами</nobr> Рамблера
                  </span>
                  <span
                    className={s.hint_description}
                    dangerouslySetInnerHTML={{ __html: phrase }}
                  />
                </span>
              </span>
            </Modal>
          )}
        </a>
        {(this.props.version === 1 ||
          this.props.version === 3 ||
          this.props.version === 4) && (
          <div className={s.copy}>
            Устанавливая программу, вы принимаете условия{' '}
            <a
              href="https://yandex.ru/legal/browser_agreement/"
              className={s.link}
              target="_blank"
            >
              Лицензионного соглашения
            </a>{' '}
            и{' '}
            <a
              href="https://yandex.ru/legal/confidential/"
              className={s.link}
              target="_blank"
            >
              Политики конфиденциальности
            </a>
          </div>
        )}
      </div>
    );
  }
}

YaBroInstallButton.propTypes = {
  version: PropTypes.number.isRequired,
  queryParams: PropTypes.string,
};

export default YaBroInstallButton;
