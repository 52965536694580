import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { LinkWithModal } from '../AppPage';
import SlideTextContent from '../SlideTextContent';

import s from './index.scss';

class SlideHoroscopes extends Component {
  render() {
    return React.createElement(
      this.props.elementType,
      { className: s.slide_horoscopes },
      <React.Fragment>
        <div className={s.graphic_content}>
          <div className={s.decorations}>
            <div className={s.phones}>
              <div className={s.l}>
                <div className={s.phone} />
              </div>
              <div className={s.r} />
            </div>
            <div className={s.decor1} />
            <div className={s.decor2} />
            <div className={s.decor3} />
            <div className={s.decor4} />
            <div className={s.decor5} />
            <div className={s.decor6} />
            <div className={s.decor7} />
            <div className={s.decor8} />
          </div>
        </div>
        <div className={s.text_content}>
          <SlideTextContent
            inverted={true}
            title={
              '<h1>Рамблер/гороскопы<br>' + 'сделают всё тайное явным</h1>'
            }
            description={
              'Наш лунный и астрологический прогноз на завтра, ' +
              'неделю, год, а также самый полный сонник <nobr>для ' +
              'трактовки</nobr> ваших снов.'
            }
          >
            <a
              href="https://redirect.appmetrica.yandex.com/serve/172250345836206603"
              className={s.button_huawei_store}
              target="_blank"
              data-soft={
                this.props.stat && this.props.stat.appPlatformType
                  ? this.props.stat.appPlatformType +
                    '::slider::button::horoscopes::android'
                  : 'product::horoscopes::button_1::android'
              }
            />
            <a
              href="https://redirect.appmetrica.yandex.com/serve/460480723044866497"
              className={s.button_ru_store}
              target="_blank"
              data-soft={
                this.props.stat && this.props.stat.appPlatformType
                  ? this.props.stat.appPlatformType +
                    '::slider::button::horoscopes::rustore'
                  : 'product::horoscopes::button_1::rustore'
              }
            />
            <span id="LinkWithModal_horoscopes1">
              <LinkWithModal
                href="https://redirect.appmetrica.yandex.com/serve/388649341109200746"
                className={s.button_google_app}
                dataSoft={'product::horoscopes::button_1::download'}
              />
            </span>
            {this.props.children}
          </SlideTextContent>
        </div>
      </React.Fragment>
    );
  }
}

SlideHoroscopes.propTypes = {
  elementType: PropTypes.string.isRequired,
  stat: PropTypes.shape({
    appPlatformType: PropTypes.string,
    place: PropTypes.string,
  }),
};

SlideHoroscopes.defaultProps = {
  elementType: 'div',
};

export default SlideHoroscopes;
