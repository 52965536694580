import React, { Component } from 'react';
import PropTypes from 'prop-types';

import SlideTextContent from '../SlideTextContent';

import s from './index.scss';

class SlideDating extends Component {
  render() {
    return React.createElement(
      this.props.elementType,
      { className: s.slide_dating },
      <React.Fragment>
        <div className={s.graphic_content}>
          <div className={s.decorations}>
            <div className={s.phones}>
              <div className={s.l}>
                <div className={s.phone}>
                  <div className={s.decor2} />
                </div>
              </div>
              <div className={s.r} />
            </div>
            <div className={s.decor1} />
            <div className={s.decor3} />
            <div className={s.decor4} />
            <div className={s.decor5} />
          </div>
        </div>
        <div className={s.text_content}>
          <SlideTextContent
            inverted={true}
            title={
              '<h1>Рамблер/знакомства<br>' +
              'помогут найти друзей и любовь</h1>'
            }
            description={
              'Здесь можно найти настоящую любовь, завести горячий ' +
              'роман или просто пофлиртовать и хорошо провести ' +
              'время.'
            }
          >
            <a
              href={
                'https://redirect.appmetrica.yandex.com/serve/818311825710907649' +
                (this.props.stat && this.props.stat.place
                  ? '?place_id=' + this.props.stat.place
                  : '')
              }
              className={s.button_apple_store}
              target="_blank"
              data-soft={
                this.props.stat && this.props.stat.appPlatformType
                  ? this.props.stat.appPlatformType +
                    '::slider::button::dating::ios'
                  : 'product::dating::button_1::ios'
              }
            />
            {this.props.children}
          </SlideTextContent>
        </div>
      </React.Fragment>
    );
  }
}

SlideDating.propTypes = {
  elementType: PropTypes.string.isRequired,
  stat: PropTypes.shape({
    appPlatformType: PropTypes.string,
    place: PropTypes.string,
  }),
};

SlideDating.defaultProps = {
  elementType: 'div',
};

export default SlideDating;
