import React, { Component } from 'react';
import Modal from '@rambler-components/modal';
import Button from '@rambler-components/button';

import '@rambler-components/modal/styles.css?compiled';
import '@rambler-components/button/styles.css?compiled';
import s from './index.scss';

class AppDownloadModal extends Component {
  render() {
    return(
      <div>
      <Modal
        width={300}
        isOpen={this.props.isOpen}
        onClose={this.props.onClose}
      >
        <div className={s.root}>
          <div className={s.title}>
            Если установка<br/>заблокируется
          </div>
          <div className={s.description}>
            Если при открытии файла APK установка была заблокирована, нажмите в появившемся
            уведомлении <strong>Настройки</strong>, а затем включите <strong>Разрешение
            для этого источника</strong>. Вернитесь назад и откройте APK заново.
          </div>
          <Button
            isRound
            onClick={this.props.onClose}
            style={{ width: '100%' }}
          >
            Понятно
          </Button>
        </div>
      </Modal>
      </div>
    );
  }
}

export default AppDownloadModal;
