import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { LinkWithModal } from '../AppPage';
import SlideTextContent from '../SlideTextContent';
import AppDownloadModal from '../AppDownloadModal';

import s from './index.scss';

class SlideMailM extends Component {
  constructor() {
    super();
    this.state = {
      isModalOpened: false,
    };
    this.handleAppClick = this.handleAppClick.bind(this);
  }

  handleAppClick() {
    // window.appStateDownloadModalVisible = true;
    this.setState({
      isModalOpened: true,
    });
  }

  render() {
    return React.createElement(
      this.props.elementType,
      { className: s.slide_mail_m },
      <React.Fragment>
        <div className={s.graphic_content}>
          <div className={s.decorations}>
            <div className={s.phones}>
              <div className={s.l}>
                <div className={s.phone}>
                  <div className={s.decor2} />
                </div>
              </div>
              <div className={s.r} />
            </div>
            <div className={s.decor1} />
            <div className={s.decor3} />
            <div className={s.decor4} />
            <div className={s.decor5} />
          </div>
        </div>
        <div className={s.text_content}>
          <SlideTextContent
            title={
              '<h1>Рамблер/почта —<br/>' +
              'все почтовые ящики <nobr>в одном</nobr> приложении</h1>'
            }
            description={
              'Официальное бесплатное приложение Рамблер/почты ' +
              '<nobr>с возможностью</nobr> сбора писем <nobr>с других</nobr> ящиков ' +
              '<nobr>и встроенным</nobr> списком задач.'
            }
          >
            <a
              href="https://redirect.appmetrica.yandex.com/serve/532764277224855368"
              className={s.button_ru_store}
              target="_blank"
              data-soft={
                this.props.stat && this.props.stat.appPlatformType
                  ? this.props.stat.appPlatformType +
                    '::slider::button::mail_m::rustore'
                  : 'product::mail_m::button_1::rustore'
              }
            />
            <span id="LinkWithModal_mailm1">
              <LinkWithModal
                href="https://redirect.appmetrica.yandex.com/serve/965110089922992189"
                className={s.button_google_app}
                dataSoft={'product::mail_m::button_1::download'}
              />
            </span>
            {this.props.children}
          </SlideTextContent>
        </div>
        <AppDownloadModal
          isOpen={this.state.isModalOpened}
          onClose={() => {
            this.setState({
              isModalOpened: false,
            });
          }}
        />
      </React.Fragment>
    );
  }
}

SlideMailM.propTypes = {
  elementType: PropTypes.string.isRequired,
  stat: PropTypes.shape({
    appPlatformType: PropTypes.string,
    place: PropTypes.string,
  }),
};

SlideMailM.defaultProps = {
  elementType: 'div',
};

export default SlideMailM;
