export const START_CLICK_INSTALL = 'start_click_install';
export const START_CLICK_INSTALL_SUCCESS = 'start_click_install_success';
export const START_CLICK_INSTALL_CANCEL = 'start_click_install_cancel';
export const START_5_SCREENS_WATCHED = 'start_5_screens_watched';
export const YABRO_CLICK_INSTALL_1 = 'yabro_click_install_1';
export const YABRO_CLICK_INSTALL_2 = 'yabro_click_install_2';
export const YABRO_CLICK_INSTALL_ALL = 'yabro_click_install_all';
export const YABRO_SLIDE_CLICK_INSTALL_1 = 'yabro_slide_click_install_1';
export const MEDIAUP_CLICK_INSTALL = 'mediaup_click_install';

const singleGoals = {
  [START_5_SCREENS_WATCHED]: false,
};

/**
 * @param {string} goalName
 * @param {number} metrikaCounterId
 */
export function goal(goalName, metrikaCounterId = 26557842) {
  if (singleGoals[goalName]) return;
  try {
    window.ym(metrikaCounterId, 'reachGoal', goalName, {}, () => {
      if (singleGoals[goalName] !== undefined) singleGoals[goalName] = true;
    });
  } catch (e) {}
}
