import React, { Component } from 'react';

import './style.scss?compiled';

class RamblerLikes extends Component {
  componentDidMount() {
    const init = () => {
      const style = {
        borderColor: 'none',
        borderColorHover: 'none',
        borderWidth: 0,
        borderWidthHover: 0,
        buttonHeight: this.props.size,
        iconSize: this.props.iconSize,
        borderRadius: 50,
        borderWidth: 2,
      };
      if (this.props.buttonBackground) {
        style.buttonBackground = this.props.buttonBackground;
      }
      RamblerShare.init('.' + this.props.id, {
        style: style,
        utm: 'utm_medium=social',
        counters: false,
        buttons: ['copy', 'email', 'vkontakte', 'facebook', 'odnoklassniki', 'whatsapp'],
      });
    };
    if (typeof RamblerShare !== 'undefined') {
      init();
    } else {
      const script = document.createElement('script');
      script.onload = init;
      script.async = true;
      script.src = 'https://developers.rambler.ru/likes/v1/widget.js';
      document.head.appendChild(script);
    }
  }

  shouldComponentUpdate() {
    return false;
  }

  render() {
    return <div className={this.props.id + ' rambler-share'} />;
  }
}

export default RamblerLikes;
