import React, { Component } from 'react';

import s from './index.scss';

class SlideTextContent extends Component {
  render() {
    return (
      <div
        className={
          s.text_content + ' ' + (this.props.inverted ? s.inverted : '')
        }
      >
        <div className={s.l} />
        <div className={s.r}>
          <div className={s.promo}>
            <div className={s.title_and_description}>
              <div
                className={s.icon}
                style={this.props.iconStyle || {}}
              >
                {this.props.icon}
              </div>
              <div
                className={s.title}
                style={this.props.titleStyle || {}}
                dangerouslySetInnerHTML={{ __html: this.props.title }}
              />
              <p
                className={s.description}
                style={this.props.descriptionStyle || {}}
                dangerouslySetInnerHTML={{ __html: this.props.description }}
              />
            </div>
            {this.props.children}
          </div>
        </div>
      </div>
    );
  }
}

export default SlideTextContent;
