export default {
  categories: [
    {
      path: '',
      menu: 'Все',
      title: 'Все программы',
    },
    // {
    //   path: 'desktop',
    //   menu: 'Для компьютера',
    //   title: 'Программы для компьютера',
    // },
    /*{
      path: 'browser-exntensions',
      menu: 'Браузерные расширения',
      title: 'Браузерные расширения',
    },*/
    {
      path: 'mobile',
      menu: 'Приложения',
      title: 'Приложения для смартфона',
    },
    {
      path: 'voice',
      menu: 'Навыки Алисы',
      title: 'Навыки для Алисы от Яндекса',
    },
  ],
  pages: [
    {
      id: 'mail_m',
      title: 'Рамблер/почта',
      description:
        'Мобильный почтовый клиент <nobr>с возможностью</nobr> сбора писем <nobr>с других</nobr> ящиков',
      type: 'mobile',
    },
    // {
    //   id: 'news',
    //   title: 'Рамблер/новости',
    //   description:
    //     'Лента важнейших событий дня, фото и видео, погода и курсы валют <nobr>в вашем</nobr> смартфоне',
    //   type: 'mobile',
    // },
    {
      id: 'horoscopes',
      title: 'Рамблер/гороскопы',
      description:
        'Ваш зодиакальный и лунный гороскоп, <nobr>а также</nobr> самый полный сонник для мобильных устройств',
      type: 'mobile',
    },
    {
      id: 'dating',
      title: 'Рамблер/знакомства',
      description:
        'Знакомства для свиданий поблизости и анонимные чаты <nobr>на самые</nobr> сокровенные темы',
      type: 'mobile',
    },
    // {
    //   id: 'kassa',
    //   title: 'Рамблер/касса',
    //   description:
    //     'Покупайте билеты <nobr>в кино</nobr>, театры, на концерты и спортивные матчи всего <nobr>в одно</nobr> касание',
    //   type: 'mobile',
    // },
    // {
    //   id: 'mediaup',
    //   title: 'MediaUp',
    //   description: 'Ваша персональная лента всего самого интересного, что есть в интернете',
    //   type: 'mobile',
    // },
    // {
    //   id: 'rambler-app',
    //   title: 'Рамблер',
    //   description: 'Важные новости и полезные сервисы в одном приложении для iOS и Android',
    //   type: 'mobile',
    // },
    // {
    //   id: 'start',
    //   title: 'Рамблер/старт',
    //   description:
    //     'Погода, пробки, курсы валют, новости и рекомендации <nobr>в Новой</nobr> вкладке браузера',
    //   type: 'browser-exntensions',
    //   chromeExtensionUrl:
    //     'https://chrome.google.com/webstore/detail/gignacddmmmefainlfmefklgboboiebj',
    // },
    // {
    //   id: 'weather',
    //   title: 'Рамблер/погода',
    //   description:
    //     'Расширение мгновенно покажет прогноз погоды <nobr>в любом</nobr> месте планеты',
    //   type: 'browser-exntensions',
    //   chromeExtensionUrl:
    //     'https://chrome.google.com/webstore/detail/mnellcecpgpeniolniglgfdfphfeoffo',
    // },
    // {
    //   id: 'media',
    //   title: 'Рамблер/медиа',
    //   description:
    //     'Обновляемая лента главных новостей <nobr>в Новой</nobr> вкладке вашего браузера',
    //   type: 'browser-exntensions',
    //   chromeExtensionUrl:
    //     'https://chrome.google.com/webstore/detail/eflnfgjcfmhdpcaadiimlmclcmjpeaki',
    // },
    // {
    //   id: 'yandex-browser',
    //   title: 'Яндекс.Браузер',
    //   description:
    //     'Быстрый и безопасный браузер <nobr>от Яндекса</nobr> <nobr>с расширениями</nobr> и закладками сервисов Рамблера',
    //   type: 'desktop',
    // },
    {
      id: 'alice-horoscopes',
      title: 'Навык Алисы',
      description:
        'Астрологический навык Рамблер/гороскопов для устройств с Алисой от Яндекса',
      type: 'voice',
    },
  ],
};
