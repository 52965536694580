import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isMobile from 'ismobilejs';

import data from '../../data';

import s from './index.scss';

const SLIDE_SHOW_DURATION = 20000;
const SLIDE_CHANGE_ANIMATION_DURATION = 400;

export class Slider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // Options.
      slidesAnimated: true,
      paginationControl: true,

      // States.
      currentSlide: 1,
      slideChangeTime: Date.now(),
      slideWidth: null,
    };
    this.prevSlide = this.prevSlide.bind(this);
    this.nextSlide = this.nextSlide.bind(this);
    this.getWidth = this.getWidth.bind(this);
  }

  componentWillMount() {
    this.rewinding = false;
    // Makes sure that children is an array, even when there is only 1 child.
    this.children = React.Children.toArray(this.props.children);
    if (this.children.length) {
      this.children.push(this.children[0]);
    }
    this.slidesCount =
      React.Children.count(this.children) - (this.children.length ? 1 : 0);
  }

  componentDidMount() {
    // Slideshow keyboard control.
    window.addEventListener('keydown', this.keyboardHandler.bind(this));
    window.addEventListener('resize', this.initializeSlider.bind(this));
    this.initializeSlider();
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.keyboardHandler);
    window.removeEventListener('resize', this.initializeSlider);
    clearInterval(this.timer);
  }

  getWidth(el) {
    return (el && (el.getBoundingClientRect().width || el.offsetWidth)) || 0;
  }

  keyboardHandler(e) {
    if (this.state.isSlidingNow) {
      return false;
    }
    const keyCode = e.keyCode;
    const KEYCODE_SPACE = 32;
    const KEYCODE_LEFT = 37;
    const KEYCODE_UP = 38;
    const KEYCODE_RIGHT = 39;
    const KEYCODE_DOWN = 40;
    if (
      keyCode === KEYCODE_SPACE ||
      keyCode === KEYCODE_DOWN ||
      keyCode === KEYCODE_RIGHT
    ) {
      e.preventDefault();
      this.nextSlide();
    }
    if (keyCode === KEYCODE_UP || keyCode === KEYCODE_LEFT) {
      e.preventDefault();
      this.prevSlide();
    }
    return false;
  }

  initializeSlider() {
    if (this.props.onInitialize) {
      this.props.onInitialize(this.state.currentSlide);
    }
    this.setState(
      {
        slidesAnimated: false,
        slideWidth: this.getWidth(this.refs.slider),
      },
      () => {
        if (this.children.length > 2) {
          this.timer = setInterval(() => {
            const time = Date.now();
            if (time - this.state.slideChangeTime > SLIDE_SHOW_DURATION) {
              this.nextSlide();
            }
            if (
              time - this.state.slideChangeTime >
                SLIDE_CHANGE_ANIMATION_DURATION &&
              this.rewinding === false
            ) {
              this.setState({
                isSlidingNow: false,
              });
            }
          }, 100);
        }
      }
    );
  }

  slideIncrement() {
    return this.state.currentSlide <= this.slidesCount
      ? this.state.currentSlide + 1
      : 1;
  }

  slideDecrement() {
    return this.state.currentSlide !== 1
      ? this.state.currentSlide - 1
      : this.slidesCount + 1;
  }

  /**
   * Shows previous slide or last slide if current slide is first.
   */
  prevSlide() {
    if (this.state.isSlidingNow) {
      return;
    }
    const state = {
      slideChangeTime: Date.now(),
      isSlidingNow: true,
    };
    const currentSlide = this.slideDecrement(this.state.currentSlide);
    if (currentSlide > this.slidesCount) {
      this.rewinding = true;
      this.setState(
        {
          ...state,
          slidesAnimated: false,
          currentSlide: currentSlide,
        },
        () => {
          setTimeout(() => {
            this.setState(
              {
                ...state,
                slidesAnimated: true,
                currentSlide: this.slidesCount,
              },
              () => {
                this.rewinding = false;
              }
            );
          }, SLIDE_CHANGE_ANIMATION_DURATION);
          if (this.props.onChange) {
            this.props.onChange(
              currentSlide > this.slidesCount ? this.slidesCount : currentSlide
            );
          }
        }
      );
    } else {
      this.setState(
        {
          ...state,
          slidesAnimated: true,
          currentSlide: currentSlide,
        },
        () => {
          if (this.props.onChange) {
            this.props.onChange(
              currentSlide > this.slidesCount ? this.slidesCount : currentSlide
            );
          }
        }
      );
    }
  }

  /**
   * Shows next slide or fist slide if current slide is last.
   */
  nextSlide() {
    if (this.state.isSlidingNow) {
      return;
    }
    const time = Date.now();
    this.setState(
      {
        slidesAnimated: true,
        currentSlide: this.slideIncrement(this.state.currentSlide),
        slideChangeTime: time,
        isSlidingNow: true,
      },
      () => {
        if (this.state.currentSlide > this.slidesCount) {
          this.rewinding = true;
          setTimeout(() => {
            this.setState(
              {
                slidesAnimated: false,
                currentSlide: 1,
                slideChangeTime: time,
              },
              () => {
                this.rewinding = false;
              }
            );
          }, SLIDE_CHANGE_ANIMATION_DURATION);
        }
        if (this.props.onChange) {
          this.props.onChange(
            this.state.currentSlide > this.slidesCount
              ? 1
              : this.state.currentSlide
          );
        }
      }
    );
  }

  /**
   * Shows slide specified by index.
   */
  setSlide(id) {
    const time = Date.now();
    const initialSlide = this.state.currentSlide;
    if (
      id !== this.children.length &&
      Math.abs(this.state.currentSlide - id) > 1
    ) {
      this.children.splice(this.state.currentSlide, 0, this.children[id - 1]);
      this.setState(
        {
          slidesAnimated: true,
          currentSlide: this.state.currentSlide + 1,
          slideChangeTime: time,
        },
        () => {
          this.rewinding = true;
          setTimeout(() => {
            this.children.splice(initialSlide, 1);
            this.setState(
              {
                slidesAnimated: false,
                currentSlide: id,
                slideChangeTime: time,
              },
              () => {
                this.rewinding = false;
                if (this.props.onChange) {
                  this.props.onChange(this.state.currentSlide);
                }
              }
            );
          }, SLIDE_CHANGE_ANIMATION_DURATION);
        }
      );
    } else {
      this.setState(
        {
          currentSlide: id,
          slidesAnimated: true,
          slideChangeTime: time,
          isSlidingNow: true,
        },
        () => {
          if (this.props.onChange) {
            this.props.onChange(this.state.currentSlide);
          }
        }
      );
    }
  }

  render() {
    return React.createElement(
      this.props.elementType,
      { ref: 'slider', className: s.slider },
      <React.Fragment>
        <Slides {...this.state}>{this.children}</Slides>
        {this.children.length > 2 && (
          <React.Fragment>
            <div className={s.navigation_dots}>
              <ul className={s.navigation_dots_content}>
                {[...Array(this.slidesCount)].map((el, i) => (
                  <li
                    key={i}
                    onClick={() => this.setSlide(i + 1)}
                    className={
                      this.state.currentSlide === i + 1
                        ? s.navigation_item_dot_active
                        : s.navigation_item_dot
                    }
                    data-soft={
                      this.props.stat.appPlatformType +
                      '::slider::dots::' +
                      (i + 1)
                    }
                  />
                ))}
              </ul>
            </div>
            <div className={s.navigation_arrows}>
              <div className={s.navigation_arrows_content}>
                <div
                  onClick={this.prevSlide}
                  className={s.navigation_item_arrow_prev}
                  data-soft={
                    this.props.stat.appPlatformType + '::slider::arrows::prev'
                  }
                />
                <div
                  onClick={this.nextSlide}
                  className={s.navigation_item_arrow_next}
                  data-soft={
                    this.props.stat.appPlatformType + '::slider::arrows::next'
                  }
                />
              </div>
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

Slider.propTypes = {
  elementType: PropTypes.string.isRequired,
  location: PropTypes.object,
  stat: PropTypes.shape({
    appPlatformType: PropTypes.string.isRequired,
  }).isRequired,
};

Slider.defaultProps = {
  elementType: 'div',
};

class Slides extends Component {
  componentWillMount() {
    this.slidesCount = React.Children.count(this.props.children);
  }

  render() {
    return (
      <div className={s.slides}>
        <div
          className={
            this.props.slidesAnimated ? s.slides_track_animated : s.slides_track
          }
          style={{
            width: this.props.slideWidth * this.slidesCount,
            marginLeft: -(
              (this.props.currentSlide - 1) *
              this.props.slideWidth
            ),
          }}
        >
          {this.props.slideWidth &&
            this.props.children.map((el, i) => {
              const siblingSlides = [i];
              if (i === 0) {
                siblingSlides.push(this.slidesCount - 1);
              } else {
                siblingSlides.push(i - 1);
              }
              if (i === this.slidesCount - 1) {
                siblingSlides.push(0);
              } else {
                siblingSlides.push(i + 1);
              }
              return (
                <div
                  key={i}
                  className={s.slide_container}
                  style={{
                    width: this.props.slideWidth,
                  }}
                >
                  {siblingSlides.includes(this.props.currentSlide - 1) && el}
                </div>
              );
            })}
        </div>
      </div>
    );
  }
}

import SlideMailM from '../SlideMailM';
// import SlideNews from '../SlideNews';
import SlideHoroscopes from '../SlideHoroscopes';
import SlideDating from '../SlideDating';
// import SlideKassa from '../SlideKassa';
// import SlideMediaUp from '../SlideMediaUp';
// import SlideRamblerApp from '../SlideRamblerApp';
// import SlideStart from '../SlideStart';
// import SlideWeather from '../SlideWeather';
// import SlideMedia from '../SlideMedia';
// import SlideYandexBrowser from '../SlideYandexBrowser';
import SlideAlice from '../SlideAlice';

export class SliderWithAllProducts extends Component {
  render() {
    // const installUrls = {};
    // ['start', 'media', 'weather'].forEach(
    //   e =>
    //     (installUrls[e] = data.pages.find(i => i.id === e).chromeExtensionUrl)
    // );

    // function updateChromeWebstoreItem(url) {
    //   let link = document.querySelector('link[rel="chrome-webstore-item"]');
    //   if (link) {
    //     link.setAttribute('href', url);
    //   } else {
    //     link = document.createElement('link');
    //     link.setAttribute('rel', 'chrome-webstore-item');
    //     link.setAttribute('href', url);
    //     document.querySelector('head').appendChild(link);
    //   }
    // }
    // function updateInstallUrl(slide) {
    //   switch (slide) {
    //     case 1:
    //       updateChromeWebstoreItem(installUrls.media);
    //       break;
    //     case 2:
    //       updateChromeWebstoreItem(installUrls.search);
    //       break;
    //     case 3:
    //       updateChromeWebstoreItem(installUrls.start);
    //       break;
    //     case 4:
    //       updateChromeWebstoreItem(installUrls.games);
    //       break;
    //     case 5:
    //       updateChromeWebstoreItem(installUrls.weather);
    //       break;
    //     default:
    //   }
    // }
    const slideProps = {
      ...this.props,
      elementType: 'article',
    };
    return (
      <Slider
        {...this.props}
        elementType="section"
        // onInitialize={slide => {
        //   updateInstallUrl(slide);
        // }}
        // onChange={slide => {
        //   updateInstallUrl(slide);
        // }}
      >
        {/* {(this.props.type === 'all' || this.props.type === 'mobile') && (
          <SlideMediaUp {...slideProps} />
        )}
        {(this.props.type === 'all' || this.props.type === 'mobile') && (
          <SlideRamblerApp {...slideProps} />
        )}
        {(this.props.type === 'all' || this.props.type === 'desktop') &&
          !(typeof window !== 'undefined' ? isMobile(window.navigator).any : false) &&
          <SlideYandexBrowser {...slideProps} />}
        {(this.props.type === 'all' ||
          this.props.type === 'browser-exntensions') && (
          <SlideMedia {...slideProps} installUrl={installUrls.media} />
        )}
        {(this.props.type === 'all' ||
          this.props.type === 'browser-exntensions') && (
          <SlideStart {...slideProps} installUrl={installUrls.start} />
        )}
        {(this.props.type === 'all' ||
          this.props.type === 'browser-exntensions') && (
          <SlideWeather {...slideProps} installUrl={installUrls.weather} />
        )*/}
        {(this.props.type === 'all' || this.props.type === 'mobile') && (
          <SlideMailM {...slideProps} />
        )}
        {/* {(this.props.type === 'all' || this.props.type === 'mobile') && (
          <SlideNews {...slideProps} />
        )} */}
        {(this.props.type === 'all' || this.props.type === 'mobile') && (
          <SlideHoroscopes {...slideProps} />
        )}
        {/* {(this.props.type === 'all' || this.props.type === 'mobile') && (
          <SlideKassa {...slideProps} />
        )} */}
        {(this.props.type === 'all' || this.props.type === 'mobile') && (
          <SlideDating {...slideProps} />
        )}
        {(this.props.type === 'all' || this.props.type === 'voice') && (
          <SlideAlice {...slideProps} />
        )}
      </Slider>
    );
  }
}

SliderWithAllProducts.propTypes = {
  type: PropTypes.string.isRequired,
  stat: PropTypes.shape({
    appPlatformType: PropTypes.string.isRequired,
  }).isRequired,
};
