import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isMobile from 'ismobilejs';

// import RamblerLikes from '../RamblerLikes';
import data from '../../data';

import s from './style.scss';
import './rambler-share.external.scss';

class Menu extends Component {
  constructor(props) {
    super(props);
    // this.state = {
    //   sharingVisible: false,
    // };
    // this.triggerSharing = this.triggerSharing.bind(this);
    this.rootRef = React.createRef();
  }

  // componentDidUpdate() {
  //   if (this.state.sharingVisible) {
  //     window.addEventListener('click', this.handleOutsideClick);
  //   } else {
  //     window.removeEventListener('click', this.handleOutsideClick);
  //   }
  // }

  // handleOutsideClick() {
  //   this.setState({
  //     sharingVisible: false,
  //   });
  //   window.removeEventListener('click', this.handleOutsideClick);
  // }

  // triggerSharing(e) {
  //   e.stopPropagation();
  //   this.setState({
  //     sharingVisible: !this.state.sharingVisible,
  //   });
  // }

  render() {
    const pageIds = {};
    data.pages.map(page => {
      pageIds[page.id] = page.type;
    });
    return (
      <div
        className={s.root}
        ref={this.rootRef}
        onMouseOver={() => {this.rootRef.current.style.zIndex = '15'}}
        onMouseOut={() => {this.rootRef.current.style.zIndex = '3'}}
      >
        {/* <div className={s.logo}>
          <a
            href="https://www.rambler.ru/?utm_source=soft&utm_content=head&utm_medium=logo&utm_campaign=self_promo"
            className={s.logo__rambler}
            title="Рамблер/"
            data-soft={this.props.stat.appPlatformType + '::logo::rambler'}
          >
            Рамблер/
          </a>
          <a
            href="/"
            className={s.logo__soft}
            title="софт"
            data-soft={this.props.stat.appPlatformType + '::logo::soft'}
          >
            софт
          </a>
        </div> */}
        <div className={s.links}>
          {data.categories
            .filter(
              i =>
                !(
                  (i.path === 'desktop' || i.path === 'browser-exntensions') &&
                  (typeof window !== 'undefined' ? isMobile(window.navigator).any : false)
                )
            )
            .map(
              item =>
                typeof window === 'undefined' ? (
                  <a
                    key={'path::' + item.path}
                    href={'/' + item.path}
                    className={
                      (
                        this.props.location.replace('/', '') === item.path ||
                        pageIds[this.props.location.replace('/', '')] === item.path
                      )
                        ? s.link_active
                        : s.link
                    }
                    data-soft={
                      this.props.stat.appPlatformType +
                      '::top_menu::' +
                      (item.path ? item.path : 'all')
                    }
                  >
                    {item.menu}
                  </a>
                ) : (
                  <a
                    key={'path::' + item.path}
                    href={'/' + item.path}
                    className={
                      window.location.pathname.replace('/', '') === item.path ||
                      pageIds[window.location.pathname.replace('/', '')] ===
                        item.path
                        ? s.link_active
                        : s.link
                    }
                    data-soft={
                      this.props.stat.appPlatformType +
                      '::top_menu::' +
                      (item.path ? item.path : 'all')
                    }
                  >
                    {item.menu}
                  </a>
                )
            )}
        </div>
        {/* <div
          id="menu-share-trigger"
          onClick={this.triggerSharing}
          className={
            s.share__trigger
          }
        />
        <div
          id="menu-share"
          className={
            s.share +
            (!this.state.sharingVisible ? ' ' + s.sharing_hidden : '')
          }
        >
          <RamblerLikes id="menu-share" size={36} iconSize={20} />
        </div> */}
      </div>
    );
  }
}

Menu.propTypes = {
  // location: PropTypes.string,
  stat: PropTypes.shape({
    appPlatformType: PropTypes.string.isRequired,
  }).isRequired,
};

export const MenuPure = Menu;

export default Menu;
