import 'core-js/stable';

import React from 'react';
import ReactDOM from 'react-dom';

import { SliderWithAllProducts } from './components/Slider';
// import Topline from './components/Topline';
import Header from './components/Header';
// import { MenuPure as Menu } from './components/Menu';
// import {
//   Slider as SliderYandexBrowserWelcome,
//   Slide1,
//   Slide2,
//   Slide3,
//   Slide4,
//   Slide5,
// } from './components/YandexBrowserWelcome';
import YaBroInstallButton from './components/YaBroInstallButton';
import { CatalogPure as Catalog } from './components/Catalog';
// import { QuickInstallButton } from './components/QuickInstallButton';
import RamblerLikes from './components/RamblerLikes';
// import Slideshow from './components/Slideshow';
// import { AppStart } from './components/AppStart';
import { LinkWithModal } from './components/AppPage';
import { goal, MEDIAUP_CLICK_INSTALL } from '../common/analytics';

import data from './data';

import sAppPage from './components/AppPage/apps.scss';

const wl = window.location;
const protocol = wl.protocol;
const host = wl.host;
const search = wl.search;

const pathname = wl.pathname.replace(/\//g, '');
const activeCategory = data.categories.find(item => item.path === pathname);
const activePage = data.pages.find(item => item.type === pathname);
let appPlatformType;
if (activeCategory && activeCategory.path.length) {
  appPlatformType = activeCategory.path;
} else if (activePage && activePage.length) {
  appPlatformType = activePage.type;
} else if (pathname === '') {
  appPlatformType = 'all';
}

// const topline = document.getElementById('topline-container');
// if (topline) {
//   ReactDOM.render(<Topline currentServerTime={topline.dataset.serverTime} />, topline);
// }

// const header = document.getElementById('header-container');
// if (header) {
//   ReactDOM.render(<Header />, header);
// }

// const menu = document.getElementById('menu');
// if (menu) {
//   ReactDOM.render(<Menu stat={{ appPlatformType: appPlatformType }} />, menu);
// }

const header = document.getElementById('header-container');
// console.log(window.location.pathname);
if (header) {
  ReactDOM.render(
  <Header
    path={window.location.pathname.replace('/', '')}
    currentServerTime={Date.now()}
  />,
  header
);
}

if (search) {
  const links = document.querySelectorAll('a');
  Array.prototype.map.call(links, function(el, i) {
    if (
      el.href.indexOf(protocol + '//' + host) === 0 &&
      el.href.indexOf('?') === -1
    ) {
      el.href += search;
    }
  });
}

// [1, 2].forEach(i => {
//   const storeButtons = document.getElementById('mediaup_install_' + i);
//   if (storeButtons) {
//     const links = storeButtons.querySelectorAll('a');
//     Array.prototype.map.call(links, function(el, i) {
//       if (
//         el.href.indexOf('appmetrica.yandex') !== -1
//       ) {
//         el.onclick = () => {
//           goal(MEDIAUP_CLICK_INSTALL);
//         }
//       }
//     });
//   }
// });

const slider = document.getElementById('slider');
if (slider) {
  ReactDOM.hydrate(
    <SliderWithAllProducts
      type={appPlatformType}
      stat={{ appPlatformType: appPlatformType, place: 'slider' }}
    />,
    slider
  );
}

[1, 2, 3, 4].forEach(i => {
  const installButton = document.getElementById('yabro_install_' + (i + 1));
  if (installButton) {
    let stat = { appPlatformType: 'desktop' };
    if (i === 3) {
      stat.appPlatformType = 'all';
    }
    let YaBroQueryParams;
    if (search) {
      YaBroQueryParams = search.replace('?', '');
    }
    ReactDOM.hydrate(
      <YaBroInstallButton
        queryParams={YaBroQueryParams}
        version={i + 1}
        stat={stat}
      />,
      installButton
    );
  }
});

const catalog = document.getElementById('catalog');
if (catalog) {
  const catalogItem = data.categories.find(i => i.path === pathname);
  if (catalogItem) {
    ReactDOM.render(
      <Catalog
        title={catalogItem.title}
        apps={data.pages.filter(
          app => app.type === catalogItem.path || catalogItem.path === ''
        )}
        stat={{ appPlatformType: catalogItem.path ? catalogItem.path : 'all' }}
      />,
      catalog
    );
  }
}

// ['media'].forEach(i => {
//   const installUrl = data.pages.find(ic => ic.id === i).chromeExtensionUrl;
//   const installButton1 = document.getElementById(i + '_install_1');
//   const installButton1All = document.getElementById(i + '_install_1_all');
//   const installButton1BrowserExntensions = document.getElementById(
//     i + '_install_1_browser-exntensions'
//   );
//   const installButton2 = document.getElementById(i + '_install_2');
//   if (installButton1) {
//     ReactDOM.hydrate(
//       <QuickInstallButton
//         installUrl={installUrl}
//         stat={'product::' + i + '::button_1'}
//       />,
//       installButton1
//     );
//   }
//   if (installButton1All) {
//     ReactDOM.hydrate(
//       <QuickInstallButton
//         installUrl={installUrl}
//         stat={'all::slider::button::' + i}
//       />,
//       installButton1All
//     );
//   }
//   if (installButton1BrowserExntensions) {
//     ReactDOM.hydrate(
//       <QuickInstallButton
//         installUrl={installUrl}
//         stat={'browser-exntensions::slider::button::' + i}
//       />,
//       installButton1BrowserExntensions
//     );
//   }
//   if (installButton2) {
//     ReactDOM.hydrate(
//       <QuickInstallButton
//         installUrl={installUrl}
//         stat={'product::' + i + '::button_2'}
//       />,
//       installButton2
//     );
//   }
// });

// const sliderYandexBrowserWelcome = document.getElementById(
//   'slider-yandex-browser-welcome'
// );
// if (sliderYandexBrowserWelcome) {
//   ReactDOM.hydrate(
//     <SliderYandexBrowserWelcome
//       animation={{ active: [true, false, true, false, false] }}
//     >
//       <Slide1 />
//       <Slide2 />
//       <Slide3 />
//       <Slide4 />
//       <Slide5 />
//     </SliderYandexBrowserWelcome>,
//     sliderYandexBrowserWelcome
//   );
// }

const slideMediaUp = document.getElementById('slide_mediaup');
if (slideMediaUp) {
  ReactDOM.hydrate(
    <SlideMediaUp stat={{ place: 'top' }}>
      <br />
      <a
        href={'/mobile'}
        className={sAppPage.back_white}
        data-soft="product::mediaup::back"
      >
        <span className={sAppPage.t}>К списку приложений</span>
      </a>
    </SlideMediaUp>,
    slideMediaUp
  );
}
const slideMailM = document.getElementById('slide_mailm');
if (slideMailM) {
  ReactDOM.hydrate(
    <SlideMailM stat={{ place: 'top' }}>
      <br />
      <a
        href={'/mobile'}
        className={sAppPage.back}
        data-soft="product::mail_m::back"
      >
        <span className={sAppPage.t}>К списку приложений</span>
      </a>
    </SlideMailM>,
    slideMailM
  );
}

const ramblerLikesTrigger = document.getElementById('menu-share-trigger');
const ramblerLikes = document.getElementById('menu-share');
const ramblerLikes2 = document.getElementById('menu-share-2');
if (ramblerLikesTrigger && ramblerLikes) {
  ramblerLikes.classList.add('sharing_hidden');
  function show(el) {
    el.classList.remove('sharing_hidden');
  }
  function hide(el) {
    el.classList.add('sharing_hidden');
  }
  function toggle(el) {
    if (el.classList.contains('sharing_hidden')) {
      show(el);
      return;
    }
    hide(el);
  }
  document.addEventListener(
    'click',
    function(e) {
      if (!(e.target.id === 'menu-share-trigger')) {
        return;
      }
      toggle(ramblerLikes);
    },
    false
  );
}
if (ramblerLikes) {
  ReactDOM.hydrate(
    <RamblerLikes id="menu-share" size={36} iconSize={20} />,
    ramblerLikes
  );
}
if (ramblerLikes2) {
  ReactDOM.hydrate(
    <RamblerLikes id="menu-share-2" size={30} iconSize={16} buttonBackground={'#2856f8'} />,
    ramblerLikes2
  );
}

// import config from './landings-data';
// const slideshowWeather = document.getElementById('slideshow-weather');
// if (slideshowWeather) {
//   ReactDOM.hydrate(
//     <Slideshow
//       type="weather"
//       data={config.landings.find(el => el.id === 'weather')}
//     />,
//     slideshowWeather
//   );
// }

// const appStart = document.getElementById('app-start');
// if (appStart) {
//   ReactDOM.hydrate(<AppStart />, appStart);
// }

const pageMailM1 = document.getElementById('LinkWithModal_mailm1');
if (pageMailM1) {
  ReactDOM.hydrate(
    <LinkWithModal
      href="https://redirect.appmetrica.yandex.com/serve/965110089922992189"
      className={sAppPage.button_google_app}
      dataSoft={'product::mail_m::button_1::download'}
    />,
    pageMailM1
  );
}
const pageMailM2 = document.getElementById('LinkWithModal_mailm2');
if (pageMailM2) {
  ReactDOM.hydrate(
    <LinkWithModal
      href="https://redirect.appmetrica.yandex.com/serve/965110089922992189"
      className={sAppPage.button_google_app}
      dataSoft={'product::mail_m::button_2::download'}
    />,
    pageMailM2
  );
}
const pageRamblerApp1 = document.getElementById('LinkWithModal_ramblerapp1');
if (pageRamblerApp1) {
  ReactDOM.hydrate(
    <LinkWithModal
      href="https://redirect.appmetrica.yandex.com/serve/604822115706754118"
      className={sAppPage.button_google_app}
      dataSoft={'product::ramblerapp::button_1::download'}
    />,
    pageRamblerApp1
  );
}
const pageRamblerApp2 = document.getElementById('LinkWithModal_ramblerapp2');
if (pageRamblerApp2) {
  ReactDOM.hydrate(
    <LinkWithModal
      href="https://redirect.appmetrica.yandex.com/serve/604822115706754118"
      className={sAppPage.button_google_app}
      dataSoft={'product::ramblerapp::button_2::download'}
    />,
    pageRamblerApp2
  );
}
const pageHoroscopes1 = document.getElementById('LinkWithModal_horoscopes1');
if (pageHoroscopes1) {
  ReactDOM.hydrate(
    <LinkWithModal
      href="https://redirect.appmetrica.yandex.com/serve/388649341109200746"
      className={sAppPage.button_google_app}
      dataSoft={'product::horoscopes::button_1::download'}
    />,
    pageHoroscopes1
  );
}
const pageHoroscopes2 = document.getElementById('LinkWithModal_horoscopes2');
if (pageHoroscopes2) {
  ReactDOM.hydrate(
    <LinkWithModal
      href="https://redirect.appmetrica.yandex.com/serve/388649341109200746"
      className={sAppPage.button_google_app}
      dataSoft={'product::ramblerapp::button_2::download'}
    />,
    pageHoroscopes2
  );
}
