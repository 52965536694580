import React, { Component } from 'react';
import PropTypes from 'prop-types';

import SlideTextContent from '../SlideTextContent';

import s from './index.scss';

class SlideAlice extends Component {
  render() {
    return React.createElement(
      this.props.elementType,
      { className: s.slide_alice },
      <React.Fragment>
        <div className={s.graphic_content}>
          <div className={s.decorations}>
            <div className={s.devices}>
              <div className={s.l}>
                <div className={s.speaker} />
                <div className={s.phone} />
              </div>
              <div className={s.r} />
            </div>
            <div className={s.decor1} />
            <div className={s.decor2} />
            <div className={s.decor3} />
          </div>
        </div>
        <div className={s.text_content}>
          <SlideTextContent
            inverted={true}
            title={
              '<h1>Рамблер/гороскопы<br>' +
              '<nobr>и Алиса теперь</nobr><br>' +
              'друзья</h1>'
            }
            description={
              'Рамблер/гороскопы голосом расскажут ' +
              'астрологический прогноз во всех устройствах ' +
              '<nobr>с Алисой от Яндекса</nobr>'
            }
          >
            {this.props.page ? (
              <a
                href="https://bit.ly/alice-horoscopes-button1"
                className={s.button_alice_white}
                target="_blank"
                data-soft={
                  this.props.stat && this.props.stat.appPlatformType
                    ? this.props.stat.appPlatformType +
                      '::slider::button::alice-horoscopes'
                    : 'product::alice-horoscopes::button_1'
                }
              />
            ) : this.props.type === 'voice' ? (
              <a
                href="https://bit.ly/voice-button-alice-horoscopes"
                className={s.button}
                target="_blank"
                data-soft={
                  this.props.stat && this.props.stat.appPlatformType
                    ? this.props.stat.appPlatformType +
                      '::slider::button::alice-horoscopes'
                    : 'product::alice-horoscopes::button_1'
                }
              >
                Установить
              </a>
            ) : (
              <a
                href="https://bit.ly/button-alice-horoscopes"
                className={s.button}
                target="_blank"
                data-soft={
                  this.props.stat && this.props.stat.appPlatformType
                    ? this.props.stat.appPlatformType +
                      '::slider::button::alice-horoscopes'
                    : 'product::alice-horoscopes::button_1'
                }
              >
                Установить
              </a>
            )}
            {this.props.children}
          </SlideTextContent>
        </div>
      </React.Fragment>
    );
  }
}

SlideAlice.propTypes = {
  elementType: PropTypes.string.isRequired,
  stat: PropTypes.shape({
    appPlatformType: PropTypes.string.isRequired,
  }),
};

SlideAlice.defaultProps = {
  elementType: 'div',
};

export default SlideAlice;
